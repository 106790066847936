import Layout from '../Terms.Layout';

const UseView = () => (
  <Layout>
    <h2>Innovation Brands Corp.</h2>
    <h2>Terms of Service</h2>

    <p>Last Updated: September 30, 2024</p>

    <p>
      Welcome to Proxima! Please read on to learn the rules and restrictions that govern your use of
      our website(s), products, services and applications, including without limitations our
      partnership marketing, media transactions, content generation, direct marketing, and audience
      engagement tools and services, as offered through our website at{' '}
      <a href="https://www.proxima.ai" target="_blank" rel="noreferrer">
        www.proxima.ai
      </a>{' '}
      (the &ldquo;Services&rdquo;). If you have any questions, comments or concerns regarding these
      Terms of Service or the Services, please contact us at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a>.
    </p>

    <p>
      These Terms of Service (the &ldquo;Terms&rdquo;) are a binding contract between you and
      Innovation Brands Corp d/b/a Proxima, its parent companies, successors and assigns
      (&ldquo;Proxima&rdquo;, &ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; and
      &ldquo;us&rdquo;). BY USING THE SERVICES, CLICKING ON THE &ldquo;I ACCEPT&rdquo; OR &ldquo;GET
      STARTED&rdquo; BUTTON OR OTHER MECHANISM PROVIDED, COMPLETING YOUR ACCOUNT REGISTRATION,
      ENTERING INTO ANY AGREEMENT WITH PROXIMA THAT INCORPORATES THESE TERMS BY REFERENCE, AND/OR
      BROWSING THE WEBSITE, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND
      BY THE TERMS, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH PROXIMA, AND (3) YOU
      HAVE THE AUTHORITY TO ENTER INTO THE TERMS PERSONALLY OR ON BEHALF OF THE INDIVIDUAL NAMED AS
      THE USER AT THE TIME OF REGISTRATION, OR IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
      A COMPANY OR ORGANIZATION (&ldquo;YOUR ORGANIZATION&rdquo;), YOU HAVE THE AUTHORITY TO BIND
      YOUR ORGANIZATION TO THE TERMS (AND, IN WHICH CASE, THE TERMS &ldquo;YOU&rdquo; AND
      &ldquo;YOUR&rdquo; OR &ldquo;CUSTOMER&rdquo; WILL REFER TO YOUR ORGANIZATION).{' '}
      <b>
        IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE
        SERVICES.
      </b>
    </p>

    <p>
      IF YOU SUBSCRIBE TO ANY SUBSCRIPTION SERVICES (DEFINED BELOW) FOR A TERM (THE &ldquo;INITIAL
      PAYMENT TERM&rdquo;), THEN YOU WILL BE CHARGED FOR FEES IN CONNECTION WITH SUCH SUBSCRIPTION
      SERVICES, WHICH SHALL AUTOMATICALLY RENEW FOR ADDITIONAL PERIODS OF THE SAME DURATION AS THE
      INITIAL TERM AT OUR THEN-CURRENT FEE FOR SUCH SUBSCRIPTION SERVICES UNLESS YOU OPT OUT OF THE
      AUTOMATIC RENEWAL PRIOR TO THE EFFECTIVE DATE THEREOF, AS FURTHER DESCRIBED IN SECTION D(4).
    </p>

    <p>
      Your use of the Services in any way means that you agree to all of these Terms, and these
      Terms will remain in effect while you use the Services. These Terms include the provisions in
      this document, as well as those in the{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      ,{' '}
      <a href="/terms/copyright" target="_blank" rel="noreferrer">
        Copyright Dispute Policy
      </a>
      , any Data Service Agreement entered into between you and Proxima (&ldquo;Service
      Agreement&rdquo;), and any other terms or policies that may be made available by us from time
      to time (collectively &ldquo;Supplemental Terms&rdquo;), which such Supplemental Terms are
      incorporated herein by reference. If these Terms are inconsistent with the Supplemental Terms,
      the Supplemental Terms shall control with respect to the applicable service, unless otherwise
      stated in the Supplemental Terms.
    </p>

    <h3>A. Modification to these Terms</h3>

    <p>
      We are constantly trying to improve our Services, so these Terms may need to change along with
      the Services. We reserve the right to change the Terms at any time, but if we do, we will
      notify you, such as by placing a notice on the{' '}
      <a href="https://www.proxima.ai" target="_blank" rel="noreferrer">
        www.proxima.ai
      </a>{' '}
      website, sending you an email to the email address you used to register on the Services,
      and/or by some other means.
    </p>

    <p>
      If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that
      means you will no longer be able to use the Services. If you use the Services in any way after
      a change to the Terms is effective, that means you agree to all of the changes.
    </p>

    <p>
      Except for changes by us as described here, no other amendment or modification of these Terms
      will be effective unless in writing and signed by both you and us.
    </p>

    <h3>B. Privacy</h3>

    <p>
      We take the privacy of our users very seriously. For the current Privacy Policy, please click{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer noopener">
        here
      </a>
      .
    </p>

    <h3>C. Eligibility</h3>

    <p>
      In order to use the Services, you may be required to sign up for a user account on the
      Services (&ldquo;Account&rdquo;), and select a password and username. You promise to provide
      us with true, complete, and up to date contact and registration information about yourself,
      and to update such information as needed to ensure that it stays true, accurate, and complete.
      You may not select as your username a name that you don&rsquo;t have the right to use, or
      another person&rsquo;s name with the intent to impersonate that person. You may not transfer
      your Account to anyone else without our prior written permission. You are solely responsible
      for keeping your Account username and password confidential and for protecting the security
      thereof. You will not share your Account username or password with anyone, unless it is an
      authorized employee within your organization. You&rsquo;re responsible for any activity
      associated with your Account, whether or not authorized by you. You&rsquo;ll immediately
      notify us of any unauthorized use of your Account(s). We are not responsible for any losses
      due to stolen or hacked passwords. You acknowledge that we do not have access to your current
      password, and may only reset your password at your request.
    </p>

    <p>
      If you use the Services to connect your Account to a third-party account, such as an account
      with a payment processor, e-commerce platform, social media network, or other vendor or
      third-party service provider (each such account, a &ldquo;Third-Party Account&rdquo;), you
      understand that Proxima may access, make available and store (if applicable) any information,
      data, other materials that you have provided to and stored in your Third-Party Account,
      including, but not limited to, your name and email address as well as information about your
      End Users (defined below), including End Users&rsquo; name, email address, purchase history,
      transaction value, product details, engagement history, and shipping details
      (&ldquo;Third-Party Account Content&rdquo;). Unless otherwise specified in these Terms, all
      Third-Party Account Content shall be considered to be Your Content (as defined below) for all
      purposes of these Terms and, for the avoidance of doubt, you represent and warrant that you
      have all rights necessary to provide Third-Party Account Content to us as further described in
      Section E. Depending on the Third-Party Accounts you choose and subject to the privacy
      settings that you have set in such Third-Party Accounts, personally identifiable information
      or other sensitive information that you post to, use with, or make available through your
      Third-Party Accounts may be available to us. Please note that if a Third-Party Account or
      associated service becomes unavailable or Proxima&rsquo;s access to such Third-Party Account
      is terminated by the third-party service provider, then Third-Party Account Content may no
      longer be available on and through the Services. You may have the ability to disable the
      connection between us and your Third-Party Accounts at any time by adjusting your account
      settings on the applicable Third-Party Account. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
      THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY
      YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND PROXIMA DISCLAIMS ANY LIABILITY
      FOR PERSONALLY IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-PARTY SERVICE
      PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET IN SUCH THIRD-PARTY ACCOUNTS.
      Proxima makes no effort to review any Third-Party Account Content for any purpose, including
      but not limited to, for accuracy, legality or noninfringement, and Proxima is not responsible
      for any Third-Party Account Content.
    </p>

    <p>
      You represent and warrant that you will use the Services only in accordance with these Terms
      and in a manner that complies with any and all federal, state or local laws and regulations
      that apply to you, including laws that apply to Your Content. If your use of the Services or
      any portion thereof is prohibited by applicable laws, then you aren&rsquo;t authorized to use
      the Services. We can&rsquo;t and won&rsquo;t be responsible for your use of the Services in
      any way that violates the law.
    </p>

    <h4>Your use of the Services is subject to the following additional terms:</h4>

    <p>
      You represent, warrant, and agree that you will not contribute to or make available through
      the Services any Content, or otherwise use the Services or interact with the Services in a
      manner, that:
    </p>

    <ol>
      <li>
        <p>
          Infringes or violates the intellectual property rights or any other rights of anyone else
          (including ours);
        </p>
      </li>

      <li>
        <p>
          Violates any law or regulation, including any applicable data protection and export
          control laws;
        </p>
      </li>

      <li>
        <p>
          Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
          otherwise objectionable;
        </p>
      </li>

      <li>
        <p>
          Jeopardizes the security of your Account or anyone else&rsquo;s (such as allowing an
          unauthorized person to log in to the Services as you);
        </p>
      </li>

      <li>
        <p>
          Attempts, in any manner, to obtain the password, Account, or other security information
          from any other user;
        </p>
      </li>

      <li>
        <p>
          Violates the security of any computer network, or cracks any passwords or security
          encryption codes;
        </p>
      </li>

      <li>
        <p>
          Runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services,
          or any processes that run or are activated while you are not logged into the Services, or
          that otherwise interfere with the proper working of the Services (including by placing an
          unreasonable load on the Services&rsquo; infrastructure);
        </p>
      </li>

      <li>
        <p>
          &ldquo;Crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or
          portion of or relating to the Services or Content (through use of manual or automated
          means);
        </p>
      </li>

      <li>
        <p>Copies or stores any significant portion of the Content;</p>
      </li>

      <li>
        <p>
          Decompiles, reverse engineers, or otherwise attempts to obtain the source code or
          underlying ideas or information of or relating to the Services.
        </p>
      </li>
    </ol>
    {/* ----------- */}
    <p>
      By submitting any Content, including any information of or related to (i) any subscriber,
      customer, or other end user of your business, products or services or (ii) any other third
      party (each and collectively, &ldquo;End User&rdquo;) through the Services (collectively, the
      &ldquo;End User Information&rdquo;), you represent, warrant, and agree that: (y) any and all
      such Content (including End User Information included therein) has been collected in
      accordance with any applicable laws, rules or regulations, and (z) you have obtained all
      third-party licenses, consents and permissions needed for Proxima to use the Content
      (including the End User Information) in accordance with the terms hereof (including any
      Supplemental Terms applicable to a particular use of such End User Information). You hereby
      agree to indemnify and hold harmless Proxima, and all of its officers, directors,
      shareholders, employees and agents, with respect to any loss, claim, suit or liability
      (including attorney&rsquo;s fees and related costs), arising from the collection, use,
      processing, and/or disclosure of such End User Information.
    </p>

    <p>
      A violation of any of the foregoing is grounds for termination of your right to use or access
      the Services.
    </p>

    <h3>D. Payments and Billing</h3>

    <ol>
      <li>
        <p>
          <u>Paid Services.</u> Certain of our Services may be subject to payments now or in the
          future (the &ldquo;Paid Services&rdquo;). Please note that any payment terms presented to
          you in the process of using or signing up for a Paid Service, including without limitation
          when you enter into a Service Agreement, are deemed part of this Agreement. When you sign
          up for, access, or use any Paid Services, including subject to a Service Agreement, you
          agree to pay all amounts incurred by your Account in connection with such Paid Services.
        </p>
      </li>

      <li>
        <p>
          <u>Billing.</u> We use a third-party payment processor (the &ldquo;Payment
          Processor&rdquo;) to bill you through a payment account linked to your Account on the
          Services (your &ldquo;Billing Account&rdquo;) for use of the Paid Services. The processing
          of payments will be subject to the terms, conditions and privacy policies of the Payment
          Processor (the &ldquo;Payment Processor Terms&rdquo;) in addition to these Terms. We are
          not responsible for error by the Payment Processor. By choosing to use Paid Services, you
          (i) agree to pay us, through the Payment Processor, all charges at the prices then in
          effect for any use of such Paid Services in accordance with the then-applicable payment
          terms; (ii) authorize us, through the Payment Processor, to charge your chosen credit
          card, paypal account, or other supported payment provider (your &ldquo;Payment
          Method&rdquo;); (iii) agree to make payment using that selected Payment Method; and (iv)
          agree to comply with the Payment Processor Terms. We reserve the right to correct any
          errors or mistakes made by the Payment Processor even if it has already requested or
          received payment.
        </p>
      </li>

      <li>
        <p>
          <u>Payment Method.</u> The terms of your Payment Method shall be determined by agreements
          between you and the financial institution, credit card issuer or other provider of your
          chosen Payment Method. If we, through the Payment Processor, do not receive payment from
          you, you agree to pay all amounts due on your Billing Account upon demand.
        </p>
      </li>

      <li>
        <p>
          <u>Subscription Services.</u> Some of the Paid Services may consist of an Initial Payment
          Term, for which there is a one-time charge, followed by recurring charges at regular
          intervals as set forth on the Services when you select or purchase such Paid Services
          (&ldquo;Subscription Services&rdquo;). By choosing a Subscription Service, you authorize
          us and/or our Payment Processor to charge your Payment Method at the start of the Initial
          Payment Term and again at regular intervals thereafter. You acknowledge and agree that
          Subscription Services incur recurring charges and automatically renew, and you accept
          responsibility for all such recurring charges. YOU AGREE THAT WE MAY SUBMIT PERIODIC
          CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU CANCEL THE
          APPLICABLE SUBSCRIPTION SERVICE. IN ORDER TO CANCEL A SUBSCRIPTION SERVICE, YOU MUST EMAIL{' '}
          <a href="mailto:support@proxima.ai">support@proxima.ai</a>OR CONTACT YOUR ACCOUNT
          REPRESENTATIVE. ONCE WE HAVE CONFIRMED RECEIPT OF YOUR TERMINATION, YOU WILL RECEIVE THE
          SUBSCRIPTION SERVICES ONLY FOR THE REMAINDER OF THE BILLING PERIOD, AND WILL RECEIVE NO
          FURTHER RECURRING CHARGES. CHANGES TO PAYMENT METHOD AND TERMINATIONS OF SUBSCRIPTION
          SERVICES MUST BE SUBMITTED TO US AT LEAST FIVE (5) BUSINESS DAYS BEFORE THE NEXT
          SUBSCRIPTION RENEWAL DATE IN ORDER TO TAKE EFFECT. WE WILL NOT BE LIABLE TO YOU FOR, AND
          YOU WILL NOT BE ELIGIBLE FOR ANY REFUNDS IN CONNECTION WITH, ANY RENEWALS OF ANY
          SUBSCRIPTION SERVICES THAT YOU DID NOT TIMELY CANCEL.
        </p>
      </li>

      <li>
        <p>
          <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE
          INFORMATION FOR YOUR BILLING ACCOUNT AND PAYMENT METHOD. YOU MUST PROMPTLY UPDATE ALL
          INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE
          IN PAYMENT METHOD, BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE),
          AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS
          CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF
          SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. IF YOU
          FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING
          YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED
          YOUR PAID SERVICES AS SET FORTH ABOVE.
        </p>
      </li>

      <li>
        <p>
          <u>Change in Amount Authorized.</u> If the amount to be charged to your Billing Account
          varies from the amount you preauthorized (other than due to the imposition or change in
          the amount of state sales taxes), you have the right to receive, and we shall provide upon
          request, notice of the amount to be charged and the date of the charge. Any agreement you
          have with your payment provider will govern your use of your Payment Method. You agree
          that we may accumulate charges incurred for multiple Paid Services or during multiple
          Services terms and submit them as one or more aggregate charges during or at the end of
          each billing cycle.
        </p>
      </li>

      <li>
        <p>
          <u>Auto-Renewal for Subscription Services.</u> Unless you opt out of auto-renewal, through
          email request to <a href="mailto:support@proxima.ai">support@proxima.ai</a>or by emailing
          your account manager, any Subscription Services you have signed up for will be
          automatically extended for successive renewal periods of the same duration as the
          subscription term originally selected, at the then-current non-promotional rate. To change
          or terminate your Subscription Services at any time, email notice to{' '}
          <a href="mailto:support@proxima.ai">support@proxima.ai</a>. If you terminate a
          Subscription Service, you may use your subscription until the end of your then-current
          term; your subscription will not be renewed after your then-current term expires. However,
          you won&rsquo;t be eligible for a prorated refund of any portion of the subscription fee
          paid for the then-current subscription period.
        </p>
      </li>

      <li>
        <p>
          <u>Service Agreements.</u> When you purchase any Paid Services through a Service
          Agreement, you agree that, unless otherwise stated in the Service Agreement, all fees for
          such Paid Services are noncancellable and nonrefundable. Unpaid fees for Paid Services
          will accrue interest at the lesser of 1.5% per month or the greatest amount allowed by
          applicable law.
        </p>
      </li>

      <li>
        <p>
          <u>Free Trials and Other Promotions.</u> Any free trial or other promotion that provides
          access to a Paid Service must be used within the specified time of the trial. You must
          stop using a Paid Service before the end of the trial period in order to avoid being
          charged for that Paid Service. If you cancel prior to the end of the trial period and are
          inadvertently charged for a Paid Service, please contact us at{' '}
          <a href="mailto:support@proxima.ai">support@proxima.ai</a>
        </p>
      </li>
    </ol>

    <h3>E. Rights and Licenses</h3>

    <p>
      The materials displayed or performed or available on or through the Services, including
      without limitation, text, graphics, data, articles, photos, images, and illustrations (all of
      the foregoing, the &ldquo;Content&rdquo;) are protected by copyright and/or other intellectual
      property laws. You promise to abide by all copyright notices, trademark rules, information,
      and restrictions contained in any Content you access through the Services, and you agree that
      you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit,
      distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any
      Content not owned by you, (i) without the prior consent of the owner of that Content or (ii)
      in a way that violates someone else&rsquo;s (including Proxima&rsquo;s) rights.
    </p>

    <p>
      You understand that Proxima owns the Services. You won&rsquo;t modify, publish, transmit,
      participate in the transfer or sale of, reproduce (except as expressly provided in this
      Section), create derivative works based on or otherwise exploit any of the Services except as
      expressly permitted hereunder, or access or use any Services for the purpose of creating a
      competitive product or service.
    </p>

    <p>
      The Services may allow you to copy or download certain Content; please remember that just
      because this functionality exists, doesn&rsquo;t mean that all of the restrictions above
      don&rsquo;t apply &ndash; they do!
    </p>

    <ol>
      <li>
        <p>
          <u>Your Proprietary Rights.</u> Any Content that you post, upload, share, store or
          otherwise provide through the Services, including any End User Information, Messages,
          etc., is your &ldquo;User Content.&rdquo; You are responsible for all User Content that
          you contribute, in any manner, to the Services, and you represent and warrant that you
          have all rights necessary to do so, in the manner in which you contribute it, and that all
          such User Content is in compliance with all applicable state, federal and local laws,
          rules and regulations.
        </p>

        <p>
          In addition to the licenses that you grant in this Section E(1), our{' '}
          <a href="/terms/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          describes how we collect and use any personal information provided by you to us.
        </p>
        <p>
          If you collect, store, upload, make available, or otherwise use User Content (including
          End User Information) through the Services, you hereby grant to Proxima a worldwide,
          transferable, sublicensable, perpetual, irrevocable, non-exclusive, royalty-free license
          to: (a) use, share and otherwise process the User Content to provide the Services to you
          and to our other customers, including to enable Proxima to prioritize marketing
          communications sent on behalf of customers by using insights that Proxima develops for its
          internal business purposes from User Content provided by participating Proxima customers;
          and (b) use the End User Information to maintain and improve our products, software and
          services, including by creating aggregate and/or deidentified datasets using such End User
          Information.
        </p>
        <p>
          In addition to the license rights to the End User Information set forth above, with
          respect to all User Content that you provide to us, you hereby grant Proxima a license to
          translate, modify (for example, in order to make sure your content is viewable on an
          iPhone as well as a computer) and reproduce and otherwise act with respect to such User
          Content, in each case to enable us to operate the Services and provide the Services to you
          and our other customers, as described in more detail below, as well as to maintain and
          improve our products, software and services, including by using the User Content to train
          AI Tools (defined below). This is a license only – your ownership in User Content is not
          affected.
        </p>
        <p>
          If you store User Content in your own Account, in a manner that is not viewable by any
          other user except you (&ldquo;Private User Content&rdquo;), you grant Proxima the license
          above, as well as a license to display, perform and distribute your Private User Content
          for the sole purpose of making the Private User Content accessible to you and providing
          the Services necessary to do so.
        </p>
        <p>
          If you share User Content in a manner designated that only certain specified users can
          view (for example, a private message to one or more other users) (&ldquo;Limited Audience
          User Content&rdquo;), then you grant Proxima the licenses above, as well as a license to
          display, perform and distribute your Limited Audience User Content for the sole purpose of
          making that Limited Audience User Content accessible to such other specified users, and
          providing the Services necessary to do so. Also, you grant such other specified users a
          license to access that Limited Audience User Content, and to use and exercise all rights
          in it, as permitted by the functionality of the Services. For the avoidance of doubt, any
          disputes between you and such other users, including with respect to any Limited Audience
          User Content that such user chooses to use in violation of any conditions you have agreed
          to between you, is solely between you and such user, and we have no liability or
          obligation to you in connection with any such dispute.
        </p>
        <p>
          If you share User Content publicly on the Services and/or in a manner that more than just
          you or certain specified users can view, or if you provide us (in a direct email or
          otherwise) with any feedback, suggestions, improvements, enhancements and/or feature
          requests relating to the Services (each of the foregoing, &ldquo;Public User
          Content&rdquo;), then you grant Proxima the license above, as well as a license to
          display, perform and distribute your Public User Content for the purpose of making that
          Public User Content accessible to all Proxima users and providing the Services necessary
          to do so, as well as all other rights necessary to use and exercise all rights in that
          Public User Content in connection with the Services and/or otherwise in connection with
          Proxima&rsquo;s business for any purpose. Also, you grant all other users of the Services
          a license to access that Public User Content, and to use and exercise all rights in it, as
          permitted by the functionality of the Services.
        </p>
        <p>
          Our Services may include, link to, or use certain generative AI tools (including AI
          Services made available by third parties, as further described below, and collectively the
          &ldquo;AI Tools&rdquo;) to collect, analyze, and respond to user prompts and requests and
          produce text, images, marketing creatives, or other content (&ldquo;Output&rdquo;). By
          using the any AI Tools, you hereby consent and authorize us to share any Content or other
          information you provide to the Services with the AI Tools in order to complete your
          request.
        </p>
        <p>
          You agree that the licenses you grant us in and to your User Content are non-exclusive,
          royalty-free, perpetual, sublicensable (through multiple tiers), irrevocable and
          worldwide, provided that when you delete your Proxima Account, we will stop displaying
          your User Content (other than Public User Content, which may remain fully available) to
          other users (if applicable), but you understand and agree that it may not be possible to
          completely delete the content from Proxima&rsquo;s records, and that your User Content may
          remain viewable elsewhere, including in deidentified or anonymized form. In particular,
          and without limitation, we may continue to use any AI Tools that were trained on your User
          Content.
        </p>
        <p>
          Finally, you understand and agree that, in performing the required technical steps to
          provide the Services to our users (including you), Proxima may need to modify your User
          Content, including to conform and adapt such User Content to the technical requirements of
          connection networks, devices, services or media, and that the foregoing license includes
          the rights to do so.
        </p>
      </li>

      <li>
        <p>
          <u>Copyright infringement.</u> You may have heard of the Digital Millennium Copyright Act
          (the &ldquo;DMCA&rdquo;), as it relates to online service providers, like Proxima, being
          asked to remove material that allegedly violates someone&rsquo;s copyright. We respect
          others&rsquo; intellectual property rights, and we reserve the right to delete or disable
          Content alleged to be infringing and to terminate the Accounts of repeat alleged
          infringers; to review our complete Copyright Dispute Policy and learn how to report
          potentially infringing content, click{' '}
          <a href="/terms/copyright" target="_blank" rel="noreferrer">
            here
          </a>
          . To learn more about the DMCA, click{' '}
          <a href="https://www.copyright.gov/legislation/dmca.pdf" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
      </li>

      <li>
        <p>
          <u>Responsibility for information and material available on the Services.</u> Any
          information or Content publicly posted or privately transmitted through the Services is
          the sole responsibility of the person from whom such Content originated, and you access
          all such information and content at your own risk, and we aren&rsquo;t liable for any
          errors or omissions in that information or content or for any damages or loss you might
          suffer in connection with it. We cannot control and have no duty to take any action
          regarding how you may interpret and use the Content or what actions you may take as a
          result of having been exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services. We can&rsquo;t
          guarantee the identity of any users with whom you interact in using the Services and are
          not responsible for which users gain access to the Services.
        </p>

        <p>
          You are responsible for all Content you contribute, in any manner, to the Services, and
          you represent and warrant you have all rights necessary to do so, in the manner in which
          you contribute it.
        </p>

        <p>
          Certain AI Tools made available through the Services may provide you with Output. To the
          extent that we have ownership rights in such Output, we hereby grant you a limited,
          revocable license to use the Output for your internal business purposes in connection with
          the Services. You agree that you shall not, and shall not cause or permit any third party
          to, resell or otherwise use the AI Tools or the Output for any purpose except as expressly
          permitted under these Terms. You acknowledge and agree that that you, and not we, shall be
          responsible for your use of any AI Tools and your use of or reliance on any Output. The AI
          Tools and the Output may produce results that are inaccurate or inappropriate, and you
          agree that any use of or reliance on the AI Tools and/or Output is at your own risk.
        </p>
      </li>
    </ol>

    <h3>F. Third Party Websites and Services</h3>

    <p>
      The Services may contain links or connections to third party websites or services that are not
      owned or controlled by Proxima. When you access third party websites or use third party
      services, you accept that there are risks in doing so, and that Proxima is not responsible for
      such risks. We encourage you to be aware when you leave the Services and to read the terms and
      conditions and privacy policy of each third party website or service that you visit or
      utilize.
    </p>

    <p>
      Proxima has no control over, and assumes no responsibility for, the content, accuracy, privacy
      policies, or practices of or opinions expressed in any third party websites or by any third
      party that you interact with through the Services. In addition, Proxima will not and cannot
      monitor, verify, censor or edit the content of any third party site or service. By using the
      Services, you release and hold us harmless from any and all liability arising from your use of
      any third party website or service, including any AI Services (defined below).
    </p>

    <p>
      Your interactions with organizations and/or individuals found on or through the Services,
      including payment and delivery of goods or services, and any other terms, conditions,
      warranties or representations associated with such dealings, are solely between you and such
      organizations and/or individuals. You should make whatever investigation you feel necessary or
      appropriate before proceeding with any online or offline transaction with any of these third
      parties. You agree that Proxima shall not be responsible or liable for any loss or damage of
      any sort incurred as the result of any such dealings.
    </p>

    <p>
      Our Service may utilize certain third-party artificial intelligence and deep learning
      platforms, algorithms, tools and models (&ldquo;AI Services&rdquo;) in order to provide and
      improve our Service. You acknowledge and agree that we may share your User Content with the AI
      Services for this purpose and such AI Services may not be required to maintain the
      confidentiality of any of your User Content. Further, you understand that additional license
      requirements may apply to certain AI Services, including that such AI Services may retain
      certain rights to use or disclose your User Content, including to further train their
      algorithmic models. You must review and comply with any such requirements prior to your use of
      any service that relies on AI Services. You assume all risks associated with your use of such
      AI Services. You agree that we will have no liability for the unavailability of any AI
      Services, or any third party&rsquo;s decision to discontinue, suspend or terminate any AI
      Services.
    </p>

    <p>
      We are not responsible for the behavior of any advertisers, or linked third party websites
      available on or through the Services. The inclusion of any advertisement or hyperlink on the
      Services does not imply Proxima&rsquo;s endorsement or any association between Proxima and the
      advertised brand or operators of the website corresponding to that hyperlink. You further
      acknowledge and agree that Proxima shall not be responsible or liable, directly or indirectly,
      for any damage or loss caused or alleged to be caused by or in connection with the use of or
      reliance on any such content, goods or services available on or through any such hyperlinked
      website.
    </p>

    <h3>G. Disputes Between Users or With Third Parties.</h3>

    <p>
      If there is a dispute between participants on this site, or between users and any third party,
      you agree that Proxima is under no obligation to become involved. In the event that you have a
      dispute with one or more other users, you release Proxima, its officers, employees, agents and
      successors from claims, demands, and damages of every kind or nature, known or unknown,
      suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to
      such disputes and/or our Services. If you are a California resident, you shall and hereby do
      waive California Civil Code Section 1542, which says: &ldquo;A general release does not extend
      to claims which the creditor or releasing party does not know or suspect to exist in his or
      her favor at the time of executing the release, which, if known by him or her must have
      materially affected his or her settlement with the debtor or released party.&rdquo;
    </p>

    <h3>H. Changes to the Services.</h3>

    <p>
      We&rsquo;re always trying to improve the Services, so they may change over time. We may
      suspend or discontinue any part of the Services, or we may introduce new features or impose
      limits on certain features or restrict access to parts or all of the Services. We&rsquo;ll try
      to give you notice when we make a material change to the Services that might affect you, but
      this isn&rsquo;t always practical, and we reserve the right to modify or discontinue any
      Services or any part of the Services without notice to you. Similarly, we reserve the right to
      remove any Content from the Services at any time, for any reason (including, but not limited
      to, if someone alleges you contributed that Content in violation of these Terms), in our sole
      discretion, and without notice.
    </p>

    <h3>I. Termination.</h3>

    <p>
      You&rsquo;re free to stop using the Services at any time, by contacting us at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a>; please refer to our{' '}
      <a href="/terms/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      , as well as the licenses above, to understand how we treat information (including User
      Content and End User Information) that you provide to us after you have stopped using our
      Services.
    </p>

    <p>
      Proxima is also free to terminate (or suspend access to) your use of the Services or your
      Account, for any reason in our discretion, including in the event that you breach these Terms.
      Proxima has the sole right to decide whether you are in violation of any of the restrictions
      set forth in these Terms.
    </p>

    <p>
      Account termination may result in destruction of any Content associated with your Account, so
      keep that in mind before you decide to terminate your Account. We will try to provide advance
      notice to you prior to terminating your Account so that you are able to retrieve any important
      User Content you may have stored in your Account (to the extent allowed by law and these
      Terms), but we may not do so if we determine it would be impractical, illegal, not in the
      interest of someone&rsquo;s safety or security, or otherwise harmful to the rights or property
      of Proxima.
    </p>

    <p>
      If you have deleted your Account by mistake, contact us immediately at{' '}
      <a href="mailto:support@proxima.ai">support@proxima.ai</a> &ndash; we will try to help, but
      unfortunately, we can&rsquo;t promise that we can recover or restore anything.
    </p>

    <p>
      Provisions that, by their nature, should survive termination of these Terms shall survive
      termination. By way of example, all of the following will survive termination: any obligation
      you have to pay us or indemnify us, any limitations of our liability, any terms regarding
      ownership or intellectual property rights, and terms regarding disputes between us.
    </p>

    <p>
      If your Account is terminated for any reason, whether by us or by you, we are not obligated to
      provide, and you should not expect, any refunds.
    </p>

    <h3>J. Limitation of Liability.</h3>

    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
      (INCLUDING WITHOUT LIMITATION TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL PROXIMA OR
      ITS LICENSORS OR SUPPLIERS OR ITS OR THEIR OFFICERS, EMPLOYEES, OR CONTRACTORS (&ldquo;PROXIMA
      PARTIES&rdquo;) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, INCIDENTAL,
      PUNITIVE, SPECIAL, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR
      LOST PROFITS, LOST REVENUE, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, LOSS OF PRODUCTION
      OR USE, BUSINESS INTERRUPTION, PROCUREMENT OF SUBSTITUTE SERVICES, ACCURACY OF RESULTS OR
      COMPUTER FAILURE OR MALFUNCTION, WHETHER OR NOT ANY OF THE PROXIMA PARTIES HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES; OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER
      OF (I) ONE HUNDRED DOLLARS ($100) OR (II) THE AMOUNTS PAID BY YOU TO PROXIMA IN CONNECTION
      WITH THE SERVICES IN THE ONE (1) MONTH PERIOD IMMEDIATELY PRECEDING THE APPLICABLE CLAIM. FOR
      THE AVOIDANCE OF DOUBT, PROXIMA PARTIES WILL HAVE NO LIABILITY ARISING FROM OR RELATED TO: (i)
      THE USE OR INABILITY TO USE THE SERVICE; (ii) ANY DATA, INFORMATION OR SERVICE PURCHASED OR
      OBTAINED; OR MESSAGES RECEIVED OR SENT THROUGH THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR
      ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OR
      THROUGH THE SERVICE; (v) YOUR USE OF, OR RELIANCE ON OUTPUT FROM, ANY AI TOOLS (INCLUDING ANY
      CHATBOT), OR (vi) ANY OTHER MATTER RELATED TO THE SERVICE, WHETHER BASED ON WARRANTY,
      COPYRIGHT, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.
      SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE
      LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
      FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN PROXIMA AND YOU.
    </p>

    <h3>K. No Warranties.</h3>

    <p>
      Neither Proxima nor its licensors or suppliers makes any representations or warranties
      concerning any Content contained in or accessed through the Services, and we will not be
      responsible or liable for the accuracy, copyright compliance, legality or decency of material
      contained in or accessed through the Services. We (and our licensors and suppliers) make no
      representations or warranties regarding suggestions or recommendations of services or products
      offered or purchased through the Services. Products and services purchased or offered (whether
      or not following such recommendations and suggestions) through the Services are provided
      &ldquo;AS IS&rdquo; and without warranty of any kind from Proxima or others (unless, with
      respect to such others only, provided expressly and unambiguously in writing by a designated
      third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY PROXIMA (AND ITS
      LICENSORS AND SUPPLIERS) ON AN &ldquo;AS IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND,
      EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF TITLE,
      NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND/OR ANY WARRANTIES
      IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, OR THAT USE OF THE SERVICES WILL BE
      UNINTERRUPTED OR ERROR-FREE. IN ADDITION, PROXIMA DOES NOT WARRANT THAT: (I) THE SERVICES WILL
      BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE
      CORRECTED; OR (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS.
    </p>

    <p>
      ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH THE SERVICE IS ACCESSED AT YOUR OWN
      RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT
      LIMITED TO, YOUR COMPUTER SYSTEM AND/OR ANY DEVICE YOU USE TO ACCESS THE SERVICE, OR ANY OTHER
      LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT
    </p>

    <p>
      YOU ACKNOWLEDGE AND AGREE THAT THE USE OF THE SERVICES AND CONTENT IS SOLELY AT YOUR OWN RISK.
    </p>

    <p>
      NO REPRESENTATION OR STATEMENT, INCLUDING WITHOUT LIMITATION, STATEMENTS REGARDING CAPACITY,
      SUITABILITY FOR USE OR PERFORMANCE OF THE SERVICES, NOT CONTAINED IN THESE TERMS WILL BE
      DEEMED TO BE A WARRANTY BY PROXIMA.
    </p>

    <p>
      FROM TIME TO TIME, PROXIMA MAY OFFER NEW &ldquo;BETA&rdquo; FEATURES OR TOOLS WITH WHICH ITS
      USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND
      WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR DISCONTINUED AT PROXIMA&rsquo;S SOLE
      DISCRETION. THE PROVISIONS OF THIS SECTION APPLY WITH FULL FORCE TO SUCH FEATURES OR TOOLS.
    </p>

    <p>
      YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH THIRD PARTIES
      ON THE SERVICE. YOU ACKNOWLEDGE AND AGREE THAT THE PROXIMA PARTIES ARE NOT LIABLE, AND YOU
      AGREE NOT TO SEEK TO HOLD THE PROXIMA PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES,
      INCLUDING OPERATORS OF EXTERNAL SITES SUCH AS THIRD-PARTY PROVIDERS OF AI SERVICES, AND THAT
      THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
    </p>

    <p>
      SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
      LIMITATIONS MAY NOT APPLY TO YOU.
    </p>

    <h3>L. Indemnity.</h3>

    <p>
      You, at your own expense, will indemnify, defend, and hold harmless Proxima, its subsidiaries,
      affiliates and assigns, and its and their respective directors, officers, employees and agents
      from and against any third-party claim, loss, demand, cause of action, debt or liability,
      including attorneys&rsquo; fees, arising out of or related to (i) your actual or alleged
      breach of any of these Terms, and (ii) your actual or alleged use or misuse of any Proxima
      Services, including, without limitation, any allegation that you used the Proxima Services to
      send any Message in violation of any federal, state, local or other law, rule, or regulation,
      including but not limited to the Telephone Consumer Protection Act (47 U.S.C. &sect; 227) and
      its implementing regulations (47 C.F.R. &sect; 64.1200).
    </p>

    <h3>M. Assignment.</h3>

    <p>
      You may not assign, delegate or transfer these Terms or any of your rights or obligations
      under these Terms, or your Services Account, in any way (by operation of law or otherwise)
      without Proxima&rsquo;s prior written consent. We may freely transfer, assign or delegate
      these Terms and our rights and obligations without notice or consent.
    </p>

    <h3>N. Choice of Law; Arbitration.</h3>

    <p>
      These Terms are governed by and will be construed under the laws of the state of New York,
      without regard to the conflict of laws provisions thereof. Any dispute arising from or
      relating to the subject matter of these Terms shall be finally settled in New York County, New
      York, in English, in accordance with the Streamlined Arbitration Rules and Procedures of
      Judicial Arbitration and Mediation Services, Inc. (&ldquo;JAMS&rdquo;) then in effect, by one
      commercial arbitrator with substantial experience in resolving intellectual property and
      commercial contract disputes, who shall be selected from the appropriate list of JAMS
      arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator
      may be entered in any court of competent jurisdiction. Notwithstanding the foregoing
      obligation to arbitrate disputes, each party shall have the right to pursue injunctive or
      other equitable relief at any time, from any court of competent jurisdiction. For all purposes
      of these Terms, the parties consent to exclusive jurisdiction and venue in the state or
      federal courts located in, respectively, New York County, New York, or the Southern District
      of New York. Any arbitration under these Terms will take place on an individual basis: class
      arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING
      INTO THESE TERMS, YOU AND PROXIMA ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO
      PARTICIPATE IN A CLASS ACTION LAWSUIT.
    </p>

    <h3>O. Miscellaneous.</h3>

    <p>
      You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and
      other governmental assessments associated with your activity in connection with the Services,
      provided that the Proxima may, in its sole discretion, do any of the foregoing on your behalf
      or for itself as it sees fit. The failure of either you or us to exercise, in any way, any
      right herein shall not be deemed a waiver of any further rights hereunder. If any provision of
      these Terms is found to be unenforceable or invalid, that provision will be limited or
      eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in
      full force and effect and enforceable. You and Proxima agree that these Terms are the complete
      and exclusive statement of the mutual understanding between you and Proxima. You hereby
      acknowledge and agree that you are not an employee, agent, partner, or joint venture of
      Proxima, and you do not have any authority of any kind to bind Proxima in any respect
      whatsoever. You and Proxima agree there are no third party beneficiaries intended under these
      Terms.
    </p>
  </Layout>
);

export default UseView;
